<template>
  <el-dialog :title="title" :visible.sync="visible" :width="width" class="ERER">
    <div
      v-loading="FileUpdateLoading"
      element-loading-text="文件正在上传中.."
      element-loading-spinner="el-icon-loading"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      top="7vh"
    >
      <el-form
        ref="ReplacephotosForm"
        :model="ReplacephotosForm"
        label-width="120px"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item
              label="人脸识别:"
              prop="ReplacephotosFace"
              style="margin-bottom: 0"
            >
              <el-radio-group v-model="ReplacephotosForm.ReplacephotosFace">
                <el-radio :label="1">开启</el-radio>
                <el-radio :label="0">不开启</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              label="上传文件:"
              prop="appointmentType"
              style="margin-bottom: 0"
            >
              <el-upload
                class="upload-demo"
                ref="uploadImport"
                :multiple="false"
                action=""
                :limit="1"
                :on-change="handleUploadChange"
                :file-list="[]"
                accept=".zip,.rar"
                :auto-upload="false"
                :show-file-list="false"
              >
                <el-button slot="trigger" size="small" type="primary"
                  >{{
                    RepUpdateLoading == true ? "文件已导入完成" : "上传文件"
                  }}
                </el-button>
              </el-upload></el-form-item
            >
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer" style="text-align: center">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button
        type="primary"
        @click="handleConfirm"
        :disabled="isPost"
        :loading="isPost"
      >
        确 定
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import { getFileMd5 } from "@/utils";
import { minioUpload, merge } from "@/api/order";
import { uploadFile } from "@/api/appreciation";
import { exportprocess } from "@/api/student";
export default {
  components: {},
  name: "uploadPhotoAudit",
  props: {
    title: {
      type: String,
      default: "上传处理完成照片",
    },
    width: {
      type: String,
      default: "450px",
    },
  },
  computed: {},
  data() {
    return {
      visible: false,
      FileUpdateLoading: false,
      isPost: false,
      RepUpdateLoading: false,
      ReplacephotosForm: {
        file: null,
        ReplacephotosFace: 1,
        collectType: "",
        id: "",
        fileName: "",
      },
      chunkSize: 5 * 1024 * 1024,
      showLoading: null,
    };
  },
  methods: {
    //查询
    initDatas(datas = {}) {
      this.visible = true;
      this.ReplacephotosForm.id = datas.id;
      this.ReplacephotosForm.collectType = datas.collectType;
      this.ReplacephotosForm.file = null;
      this.isPost = false;
      this.RepUpdateLoading = false;
      this.FileUpdateLoading = false;
    },
    async handleConfirm() {
      if (this.RepUpdateLoading != true) {
        this.$notify({
          title: "警告",
          message: "请先上传文件后在进行确认操作",
          type: "warning",
        });
      } else {
        this.FileUpdateLoading = true;
        this.isPost = true;
        let dataPar = this.ReplacephotosForm;
        let file = dataPar.file.raw;
        this.ReplacephotosForm.fileName = file.name;
        const fileMd5 = await getFileMd5(file);
        let i = 0;
        this.PostFile(file, i, fileMd5);
      }
    },
    // 分片上传文件
    PostFile(file, i, fileMd5) {
      let fileName = file.name,
        fileType = file.type,
        fileSize = file.size,
        chunkCount = Math.ceil(fileSize / this.chunkSize); //总片数
      if (i >= chunkCount) {
        return;
      }
      let start = i * this.chunkSize;
      let end = start + this.chunkSize;
      let packet = file.slice(start, end); //将文件进行切片
      let form = new FormData();
      form.append("md5", fileMd5);
      form.append("file", packet); //slice方法用于切出文件的一部分
      form.append("name", fileName);
      form.append("totalSize", fileSize);
      form.append("total", chunkCount); //总片数
      form.append("index", i + 1); //当前是第几片
      minioUpload(form).then((resp) => {
        if (resp.data.status === 20001) {
          /*  表示上一块文件上传成功，继续下一次  */
          form = "";
          i++;
          this.PostFile(file, i, fileMd5);
        } else if (resp.data.status === 50000) {
          form = "";
          /*  失败后，每2秒继续传一次分片文件  */
          setInterval(function () {
            this.PostFile(file, i, fileMd5);
          }, 2000);
        } else if (resp.data.status === 20002) {
          this.merge(chunkCount, fileName, fileMd5, fileSize, fileType);
          this.$notify({
            title: "温馨提示",
            message: resp.message,
            type: "success",
          });
        } else {
          this.$notify({
            title: "温馨提示",
            message: resp.message,
            type: "success",
          });
        }
      });
    },
    // 合并分片文件
    merge(chunkCount, fileName, fileMd5, fileSize, fileType) {
      let dataPar = this.ReplacephotosForm;
      let postData = {
        shardCount: chunkCount,
        md5: fileMd5,
        fileName: fileName,
        fileType: fileType,
        fileSize: fileSize,
        isFace: dataPar.ReplacephotosFace,
        collectType: dataPar.collectType,
        id: this.uploadId,
      };
      merge(postData).then((resp) => {
        this.FileUpdateLoading = false;
        if (resp.code == 0) {
          this.showLoading = this.$notify.info({
            title: "温馨提示",
            message: "上传处理完成照片处理中...",
            duration: 0,
            showClose: false,
          });
          let lastData = {
            fileName: resp.data.fileName,
            name: fileName,
            isFace: this.ReplacephotosForm.ReplacephotosFace,
            collectType: this.ReplacephotosForm.collectType,
            id: this.ReplacephotosForm.id,
          };
          uploadFile(lastData).then((resp) => {
            if (resp.code == 0) {
              const json = JSON.parse(resp.data);
              this.taskExportprocess(json.taskNo);
            } else {
              this.$notify({
                title: "警告",
                message: "未知错误",
                type: "warning",
              });
            }
          });
        } else {
          this.$notify({
            title: "警告",
            message: "未知错误",
            type: "warning",
          });
        }
        this.ReplacephotosVisible = false;
      });
    },
    handleCancel() {
      this.visible = false;
      this.FileUpdateLoading = false;
      this.isPost = false;
    },
    // 上传照片
    handleUploadChange(file) {
      if (file.raw) {
        this.ReplacephotosForm.file = file;
        this.RepUpdateLoading = true;
      } else {
        this.RepUpdateLoading = false;
        this.ReplacephotosForm.file = null;
      }
    },
    /**
     * 查询任务进度
     */
    taskExportprocess(taskNo, title = "") {
      let that = this;
      exportprocess(taskNo)
        .then((res) => {
          if (res.data.state == 0) {
            setTimeout(() => {
              that.taskExportprocess(taskNo, title);
            }, 500);
          } else if (res.data.state == 1) {
            this.showLoading.close();
            that.$notify({
              title: "温馨提示",
              message: "上传处理完成照片成功",
              type: "success",
              showClose: false,
            });
            this.isPost = false;
            this.visible = false;
          } else if (res.data.state == 3) {
            this.showLoading.close();
            if (res.data.msg.length > 0) {
              let num = Number(res.data.msg.length) - 1;
              this.$notify({
                title: "警告",
                message: res.data.msg[num],
                type: "warning",
                showClose: false,
              });
            }
            this.isPost = false;
          } else {
            this.showLoading.close();
            this.$notify({
              title: "警告",
              message: "上传处理完成照片失败",
              type: "warning",
              showClose: false,
            });
            this.isPost = false;
          }
        })
        .catch(() => {
          this.showLoading.close();
        });
    },
  },
  mounted() {},
};
</script>
