<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    :width="width"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      :model="resultForm"
      ref="fileForm"
      label-width="100px"
      @submit.native.prevent
      v-loading="FileUpdateLoading"
      element-loading-text="数据加载中.."
      element-loading-spinner="el-icon-loading"
    >
      <el-form-item
        label="人像识别"
        prop="mode"
        style="margin-bottom: 0"
        v-if="isFaceDetect"
      >
        <el-radio-group v-model="resultForm.isFaceDetect">
          <el-radio :label="1">启用</el-radio>
          <el-radio :label="0">不启用</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="上传照片" prop="mode" v-if="isUpload">
        <el-upload
          class="upload-demo"
          ref="uploadImport"
          :multiple="false"
          action=""
          :limit="1"
          :on-change="handleUploadChange"
          :file-list="[]"
          accept=".png,.jpg,.jpeg"
          :auto-upload="false"
          :show-file-list="false"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip" style="line-height: 100%">
            只能上传jpg/png文件，且不超过300kb
          </div>
        </el-upload>
      </el-form-item>
      <div style="padding-left: 30px">
        <div style="color: #5c7af3">共上传{{ totalElements }}张照片</div>
        <div class="img_box">
          <!-- 替换前 -->
          <div class="img_style">
            <img :src="pathCollectedPhoto" alt="" class="img" />
            <p>订单原照片</p>
          </div>
          <!-- 替换后 -->
          <div class="img_style">
            <img :src="replaceCollectedPhoto" alt="" class="img" />
            <p>替换后照片</p>
          </div>
        </div>
        <div class="order">
          订单号：{{ dataInfo.orderNo }}（{{ dataInfo.serialNumber }}组）
        </div>
      </div>
    </el-form>
    <div slot="footer" class="dialog-footer uploadClass">
      <el-button :disabled="FileUpdateLoading" @click="upReplacePhotos('not')"
        >暂不替换</el-button
      >
      <el-button
        type="primary"
        :disabled="FileUpdateLoading"
        @click="upReplacePhotos('yes')"
        >确认替换</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import axios from "axios";
import { getPhotoDetail } from "../api/appreciation";
import { getToken } from "@/utils/auth";
import { setCheckReplace } from "../api/appreciation";

export default {
  name: "photoResultsCheck",
  props: {
    title: {
      type: String,
      default: "上传结果复核",
    },
    width: {
      type: String,
      default: "560px",
    },
    isFaceDetect: {
      type: Boolean,
      default: false,
    },
    isUpload: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  data() {
    return {
      visible: false,
      FileUpdateLoading: true,
      id: "",
      pathCollectedPhoto: "../temp/none_img.jpg",
      replaceCollectedPhoto: "../temp/none_img.jpg",
      resultForm: {
        isFaceDetect: 1,
      },
      dataList: [],
      totalElements: 0,
      dataInfo: {},
      serialNumber: 0,
      showImgsCount: 0,
      isPost: false,
    };
  },
  methods: {
    //上传文件
    handleUploadChange() {},
    // 是否替换照片
    upReplacePhotos(mod) {
      let params = {
        id: this.dataInfo.id,
      };
      if (mod == "yes") {
        //确认替换
        params.status = 2;
      } else if (mod == "no") {
        //暂不替换
        params.status = 1;
      }
      if (this.isPost) {
        return false;
      }
      this.isPost = true;
      setCheckReplace(params).then((resp) => {
        this.isPost = false;
        if (resp.code == 0) {
          this.serialNumber += 1;
          const dataInfo = this.dataList[this.serialNumber];
          if (dataInfo && dataInfo.id) {
            this.pathCollectedPhoto = "../temp/none_img.jpg";
            this.replaceCollectedPhoto = "../temp/none_img.jpg";
            this.showImgsCount = 0;
            this.FileUpdateLoading = true;
            this.dataInfo = this.dataList[this.serialNumber];
            this.getPhoto(this.dataInfo.pathCollectedPhoto, "pathCollectedPhoto");
            this.getPhoto(this.dataInfo.replaceCollectedPhoto, "replaceCollectedPhoto");
          } else {
            this.$notify({
              title: "温馨提示",
              message: "上传结果复核已全部审核完成",
              type: "success",
              showClose: false,
            });
            this.pathCollectedPhoto = "../temp/none_img.jpg";
            this.replaceCollectedPhoto = "../temp/none_img.jpg";
            this.showImgsCount = 0;
            this.FileUpdateLoading = true;
            this.dataInfo = {};
            setTimeout(() => {
              this.visible = false;
            }, 2000);
          }
        }
      });
    },
    /**
     * 获取替换照片信息
     * @param {*} id
     */
    funcPhotoDetail(id) {
      getPhotoDetail(id)
        .then((res) => {
          if (res.code == 0 && res.data.content.length) {
            this.dataList = res.data.content;
            this.dataInfo = this.dataList[0];
            this.totalElements = res.data.totalElements;
            this.getPhoto(this.dataInfo.pathCollectedPhoto, "pathCollectedPhoto");
            this.getPhoto(this.dataInfo.replaceCollectedPhoto, "replaceCollectedPhoto");
          } else {
            // this.FileUpdateLoading = false;
            this.$notify({
              title: "警告",
              message: "查询替换照片任务明细无数据",
              type: "warning",
            });
            setTimeout(() => {
              this.visible = false;
            }, 2000);
          }
        })
        .catch(() => {
          // this.FileUpdateLoading = false;
          this.$notify({
            title: "警告",
            message: "查询替换照片任务明细无数据",
            type: "warning",
          });
          setTimeout(() => {
            this.visible = false;
          }, 2000);
        });
    },
    initDatas(datas) {
      this.visible = true;
      this.id = datas.id;
      this.pathCollectedPhoto = "../temp/none_img.jpg";
      this.replaceCollectedPhoto = "../temp/none_img.jpg";
      this.showImgsCount = 0;
      this.FileUpdateLoading = true;
      this.dataList = [];
      this.dataInfo = {};
      this.funcPhotoDetail(datas.id);
    },
    //显示图片
    getPhoto(fileid, imgID) {
      const url =
        "/api/csias/file/download/" + fileid + "?timestamp=" + new Date().getTime();
      axios({
        method: "GET",
        url: url,
        headers: {
          Authorization: "Bearer " + getToken(),
        },
        responseType: "blob",
        timeout: 180000,
      }).then(
        (response) => {
          if (response.status === 200 && response.data) {
            const imgContent = response.data;
            if (imgID == "pathCollectedPhoto") {
              this.pathCollectedPhoto = window.URL.createObjectURL(imgContent);
              this.showImgsCount += 1;
            } else if (imgID == "replaceCollectedPhoto") {
              this.replaceCollectedPhoto = window.URL.createObjectURL(imgContent);
              this.showImgsCount += 1;
            }
            if (this.showImgsCount == 2) {
              this.FileUpdateLoading = false;
            }
          }
        },
        () => {}
      );
    },
  },
};
</script>
<style scoped>
.img_box {
  width: 450px;
  height: 280px;
  padding: 10px 0px 0px 0px;
  display: flex;
  overflow: hidden;
  justify-content: space-between;
}
.order {
  width: 450px;
  text-align: center;
  padding: 10px 0;
}
.img_box .img_style {
  width: 180px;
  height: 240px;
  text-align: center;
}
.img_box .img_style .img {
  width: 100%;
  height: 100%;
  vertical-align: top;
}
.img_box .img_style p {
  margin: 0;
  padding-top: 10px;
}
.uploadClass {
  width: 260px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
</style>
