<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    :width="width"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    top="7vh"
    @close="clickClose"
  >
    <!-- 搜索框 -->
    <div class="search-box">
      <div class="condition-item">
        <label class="fn-14">订单号</label>
        <el-input
          v-model="search.orderNo"
          placeholder="请输入订单号"
          autocomplete="off"
          size="small"
        ></el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">摄影师</label>
        <el-select
          style="width: 180px"
          clearable
          v-model="search.collectName"
          placeholder="摄影师"
          size="small"
        >
          <el-option
            v-for="item in photographers"
            :key="item.photographerName"
            :label="item.photographerName"
            :value="item.photographerName"
            autocomplete="off"
            size="small"
          >
          </el-option>
        </el-select>
      </div>
      <div class="condition-item">
        <el-button type="primary" icon="el-icon-search" @click="handleSearch" size="small"
          >查询
        </el-button>
        <el-button type="info" icon="el-icon-refresh" @click="resetSearch" size="small"
          >重置
        </el-button>
      </div>
    </div>
    <!-- 搜索框 -->
    <!-- 图片列表集合 -->
    <div class="photo-box">
      <template v-if="photoLst != null && photoLst.length">
        <div class="item" v-for="item in photoLst" :key="item.orderNo">
          <div class="imgs">
            <img :src="item.urls" v-if="item.urls" @click="showViewImg(item.urls)" />
            <div class="image-slot" v-else>
              <i class="el-icon-picture-outline"></i>
            </div>
          </div>

          <div class="order">{{ item.orderNo }}</div>
          <div class="edit">
            <i
              class="el-icon-success"
              :class="item.checked == 1 ? 'success' : ''"
              title="合格"
              @click="photoCheck(item, 1)"
            ></i>
            <i
              class="el-icon-error"
              :class="item.checked == 2 ? 'error' : ''"
              title="不合格"
              @click="photoCheck(item, 2)"
            ></i>
          </div>
        </div>
      </template>
      <div v-else class="not-data">
        <el-result icon="info" subTitle="当前照片审核已完成" color="#909399"> </el-result>
      </div>
    </div>
    <!-- 图片列表集合 -->
    <div class="footer">
      <!-- 审核人数信息 -->
      <div class="audit">{{ unAuditCount }}人待审核/共{{ totalCount }}人</div>
      <!-- 审核人数信息 -->
      <div class="btns">
        <el-button @click="clickClose">取 消</el-button>
        <el-button
          type="primary"
          @click="clickSubmitAudit"
          :loading="isSubmit"
          :disabled="isSubmit"
          v-if="auditPhotosButton"
          >提交审核</el-button
        >
      </div>
    </div>
    <!-- 图片预览   放在表格外面，这是个弹出狂-->
    <el-dialog
      :visible.sync="showViewer"
      :modal="false"
      title="图片预览"
      width="500px"
      top="7vh"
    >
      <img :src="srcList" alt="" width="100%" />
    </el-dialog>
    <!-- 图片预览 -->
  </el-dialog>
</template>
<script>
import { queryPageButton } from "@/api/permission";

import { queryParam } from "@/api/param";
import { queryAuditPhotos, auditPhotos } from "@/api/appreciation";
import { getToken } from "@/utils/auth";
import axios from "axios";
export default {
  components: {},
  name: "photoAudit",
  props: {
    title: {
      type: String,
      default: "照片审核",
    },
    width: {
      type: String,
      default: "1000px",
    },
  },
  computed: {},
  data() {
    return {
      visible: false,
      showViewer: false,
      isSubmit: false,
      srcList: "",
      search: {
        orderNo: "",
        collectName: "",
        id: "",
        collectType: "",
      },
      photographers: [],
      photoLst: [],
      totalCount: 0,
      unAuditCount: 0,

      //权限按钮
      auditPhotosButton:false
    };
  },
  created() {
    this.queryButton();
  },
  methods: {
    queryButton() {
      const data = 222;
      queryPageButton(data).then((resp) => {
        for (const button of resp.data) {
          if (button.code === "auditPhotosButton") {
            this.auditPhotosButton = true;
          }
       
        }
      });
    },
    //查询
    handleSearch() {
      this.funcQueryAuditPhotos();
    },
    //重置
    resetSearch() {
      this.search.orderNo = "";
      this.search.collectName = "";
      this.funcQueryAuditPhotos();
    },
    clickClose() {
      this.photoLst = [];
      this.visible = false;
    },
    showViewImg(imgs) {
      this.srcList = imgs;
      this.showViewer = true;
    },
    //审核设置
    photoCheck(item, checked) {
      item.checked = checked;
    },
    // 查询图片列表
    funcQueryAuditPhotos() {
      const params = JSON.parse(JSON.stringify(this.search));
      const load = this.$loading({
        lock: true,
        text: "数据加载中",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.1)",
      });
      queryAuditPhotos(params)
        .then((resp) => {
          load.close();
          if (resp.code == 0) {
            resp.data.content.map((item) => {
              item.urls = this.getPhoto(item);
              item.checked = 1;
            });
            this.photoLst = resp.data.content;
            this.unAuditCount = resp.data.unAuditCount || 0;
            this.totalCount = resp.data.totalCount || 0;
          }
        })
        .catch(() => {
          load.close();
        });
    },
    //显示图片
    getPhoto(fileid) {
      const url =
        "/api/csias/file/download/" +
        fileid.pathCollectedPhoto +
        "?timestamp=" +
        new Date().getTime();
      axios({
        method: "GET",
        url: url,
        headers: {
          Authorization: "Bearer " + getToken(),
        },
        responseType: "blob",
        timeout: 180000,
      }).then(
        (response) => {
          if (response.status === 200 && response.data) {
            const imgContent = response.data;
            fileid.urls = window.URL.createObjectURL(imgContent);
          }
        },
        () => {}
      );
    },
    initDatas(id, collectType) {
      this.visible = true;
      this.search.id = id;
      this.search.collectType = collectType;
      this.photoLst = [];
      this.funcQueryAuditPhotos();
    },
    /**
     * 提交审核信息
     */
    clickSubmitAudit() {
      const params = {
        id: this.search.id,
        collectType: this.search.collectType,
      };
      const datas = JSON.parse(JSON.stringify(this.photoLst));
      const auditSuccess = [];
      const auditFailes = [];
      datas.map((item) => {
        if (item.checked == 1) {
          auditSuccess.push(item.id);
        } else {
          auditFailes.push(item.id);
        }
      });
      params.auditSuccess = auditSuccess.join(",");
      params.auditFailes = auditFailes.join(",");
      console.log(params);
      this.isSubmit = true;
      auditPhotos(params)
        .then((resp) => {
          this.isSubmit = false;
          if (resp.code == 0) {
            this.$notify.success({
              title: "成功",
              message: resp.message,
            });
            setTimeout(() => {
              this.funcQueryAuditPhotos();
            }, 2000);
          } else {
            this.$notify({
              title: "失败",
              message: resp.message,
              type: "warning",
            });
          }
        })
        .catch(() => {
          this.isSubmit = false;
        });
    },
  },
  mounted() {
    queryParam().then((resp) => {
      const { photographer } = resp.data;
      const ren = [];
      if (photographer) {
        const arr = photographer.split(",");
        arr.forEach((item) => {
          ren.push({ photographerName: item });
        });
      }
      this.photographers = ren;
    });
  },
};
</script>
<style scoped>
.not-data {
  margin: auto;
  padding-top: 13%;
}
.not-data .el-result :deep(.icon-info) {
  fill: #909399;
}
.photo-box {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  min-height: 482px;
}
.photo-box .item {
  padding: 0 13px;
}
.photo-box .imgs {
  width: 134px;
  height: 179px;
  position: relative;
}
.photo-box .imgs img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.photo-box .imgs :deep(.image-slot) {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #f5f7fa;
  color: #909399;
  font-size: 24px;
}
.photo-box .imgs::before {
  content: "查看大图";
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  font-size: 12px;
  padding: 5px 0;
  display: none;
}
.photo-box .imgs:hover::before {
  display: block;
}
.photo-box .item .order {
  font-size: 10px;
  padding-top: 5px;
  color: #777;
}
.photo-box .item .edit {
  text-align: center;
  padding: 8px 0;
}
.photo-box .item .edit i {
  font-size: 20px;
  color: #dddada;
  margin: 0 10px;
  cursor: pointer;
}
.photo-box .item .edit i.success {
  color: #1ccb66;
}
.photo-box .item .edit i.error {
  color: #fb4939;
}
.audit {
  text-align: left;
  padding: 10px 0 0 15px;
  color: #446cf3;
  width: 200px;
}
.footer {
  text-align: center;
  padding-top: 10px;
  display: flex;
}
.footer .btns {
  flex: 0.7;
}
.footer .el-button {
  width: 130px;
  margin: 0 20px;
}
</style>
