<template>
  <div
    style="display: flex; flex-direction: column; height: calc(100vh - 180px)"
  >
    <!-- 搜索 -->
    <div style="display: flex; flex-wrap: wrap">
      <div class="condition-item">
        <label class="fn-14">任务名称</label>
        <el-input
          v-model="search.title"
          placeholder="请输入任务名称"
          autocomplete="off"
          size="small"
        ></el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">学校名称</label>
        <el-input
          v-model="search.schoolName"
          placeholder="请输入学校名称"
          autocomplete="off"
          size="small"
        ></el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">创建时间</label>
        <el-date-picker
          v-model="search.stime"
          type="date"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          placeholder="选择开始时间"
          autocomplete="off"
          size="small"
          style="width: 160px"
        >
        </el-date-picker>
        <label class="fn-14" style="padding-left: 10px">-</label>
        <el-date-picker
          v-model="search.etime"
          type="date"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          placeholder="选择结束时间"
          autocomplete="off"
          size="small"
          style="width: 160px"
        >
        </el-date-picker>
      </div>
      <div class="condition-item">
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="handleSearch"
          size="small"
          >查询
        </el-button>
        <el-button
          type="info"
          icon="el-icon-refresh"
          @click="resetSearch"
          size="small"
          >重置
        </el-button>
      </div>
    </div>
    <!-- 搜索 -->
    <!-- 列表数据 -->
    <div style="margin-bottom: 15px; flex: 1; overflow: hidden">
      <el-table
        :data="dataList"
        v-loading="tableLoading"
        stripe
        border
        height="100%"
        style="width: 100%"
      >
        <template #empty>
          <p>{{ tableLoading == true ? "数据加载中" : "暂无数据" }}</p>
        </template>
        <el-table-column
          prop="title"
          label="采集名称"
          min-width="260"
        ></el-table-column>
        <el-table-column
          prop="batchName"
          label="拍摄对象"
          width="80"
          align="center"
        >
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.shotSubject == 0"
              type="danger"
              effect="plain"
              size="small"
              >新生</el-tag
            >
            <el-tag
              v-else-if="scope.row.shotSubject == 1"
              type="success"
              effect="plain"
              size="small"
              >毕业生
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="totalCount"
          label="采集人数"
          width="80"
        ></el-table-column>
        <el-table-column prop="beginDate" label="采集时间" width="200">
          <template slot-scope="scope">
            {{ formDateTimeFormat(scope.row.beginDate) }} -
            {{ formDateTimeFormat(scope.row.endDate) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="photographer"
          label="摄影师"
          width="140"
        ></el-table-column>
        <el-table-column prop="auditMsg" label="审核进度" width="140">
          <template slot-scope="scope">
            <div style="color: #ec0b0b">
              审核不通过：{{ scope.row.auditun || 0 }}
            </div>
            <div style="color: #ff9800">
              未审核：{{ scope.row.auditno || 0 }}
            </div>
            <div style="color: #1ccb66">
              已审核：{{ scope.row.audited || 0 }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="auditErrorMsg"
          label="审核不通过情况"
          min-width="200"
        >
          <template slot-scope="scope">
            <template v-if="scope.row.auditErrorMsg">
              <span
                v-for="_sub in scope.row.auditErrorMsg"
                :key="_sub.collectName"
                style="margin-right: 5px"
              >
                {{ _sub.collectName }}：{{ _sub.photoCount }}人
              </span>
            </template>
            <div v-else>无</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="batchName"
          label="处理情况"
          width="120"
          align="center"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="clickTaskSchedule(scope.row)"
              >查看处理进度</el-button
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="batchName"
          label="操作"
          width="200"
          align="right"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              v-if="PhotoAuditButton"
              @click="clickPhotoAudit(scope.row.id, scope.row.collectType)"
              >审核照片</el-button
            >
            <el-button
              type="text"
              v-if="DownloadPhotosButton"
              @click="clickDownloadPhotos(scope.row)"
              >下载不通过照片</el-button
            >
            <el-button
              type="text"
              @click="clickUploadPhoto(scope.row)"
              v-if="UploadPhotoButton"
              >上传处理完成照片</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 列表数据 -->
    <!-- 分页 -->
    <div style="display: flex; justify-content: right">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageConfig.currentPage"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="pageConfig.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageConfig.total"
      >
      </el-pagination>
    </div>
    <!-- 分页 -->
    <!-- 照片复核 -->
    <photoResultsCheck ref="photoResCheck" />
    <!-- 照片审核 -->
    <photoAudit ref="phptoAudits" />
    <!-- 处理进度 -->
    <progressView ref="progressTask" @funcPhotoResCheck="funcPhotoResCheck" />
    <uploadPhoto ref="photoMod" />
  </div>
</template>

<script>
import photoResultsCheck from "@/components/photoResultsCheck.vue";
import photoAudit from "../extend/photoAudit.vue";
import progressView from "../extend/progressView.vue";
import uploadPhoto from "../extend/uploadPhoto.vue";
import { queryAllTask, downloadPhotos } from "@/api/appreciation";
import { dateFormat } from "@/utils/date";
import { exportprocess } from "@/api/student";
import { downFiles } from "@/utils";
import { queryPageButton } from "@/api/permission";

export default {
  name: "reviewCjTask",
  components: {
    photoResultsCheck, //照片复核
    photoAudit, //照片审核
    progressView, //处理进度
    uploadPhoto,
  },
  data() {
    return {
      tableLoading: false,
      dataList: [],
      search: {
        title: "", //任务名称
        schoolName: "", //学校名称
        stime: "", //开始时间
        etime: "", //结束时间
      },
      pageConfig: {
        currentPage: 1,
        total: 0,
        pageSize: 20,
      },
      showLoading: null,
      //权限按钮
      PhotoAuditButton: false,
      DownloadPhotosButton:false,
      UploadPhotoButton:false,


    };
  },
  created() {
    this.queryButton();
  },
  methods: {
    queryButton() {
      const data = 222;
      queryPageButton(data).then((resp) => {
        for (const button of resp.data) {
          if (button.code === "PhotoAuditButton") {
            this.PhotoAuditButton = true;
          }
          if (button.code === "DownloadPhotosButton") {
            this.DownloadPhotosButton = true;
          }
          if (button.code === "UploadPhotoButton") {
            this.UploadPhotoButton = true;
          }
        }
      });
    },
    //查询
    handleSearch() {
      this.pageConfig.currentPage = 1;
      this.funcQueryAllTask();
    },
    //重置
    resetSearch() {
      this.pageConfig.currentPage = 1;
      this.search = {
        title: "", //任务名称
        schoolName: "", //学校名称
        stime: "", //开始时间
        etime: "", //结束时间
      };
      this.funcQueryAllTask();
    },
    //设置分页数量
    handleSizeChange(val) {
      this.pageConfig.pageSize = val;
      this.funcQueryAllTask();
    },
    //上下页
    handleCurrentChange(val) {
      this.pageConfig.currentPage = val;
      this.funcQueryAllTask();
    },
    //获取列表
    funcQueryAllTask() {
      const params = this.search;
      this.tableLoading = true;
      const load = this.$loading({
        lock: true,
        text: "数据加载中",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.1)",
      });
      queryAllTask(
        params,
        this.pageConfig.pageSize,
        this.pageConfig.currentPage
      )
        .then((resp) => {
          this.tableLoading = false;
          load.close();
          if (resp.code == 0) {
            this.dataList = resp.data.content;
            this.pageConfig.total = resp.data.totalElements;
          }
        })
        .catch(() => {
          this.tableLoading = false;
          load.close();
        });
    },
    formDateTimeFormat(dateValue, format = "YYYY-mm-dd") {
      if (dateValue != null) {
        return dateFormat(format, new Date(dateValue));
      }
    },
    /**
     * 点击审核照片
     * @param {*} id
     */
    clickPhotoAudit(id, collectType) {
      if (this.$refs.phptoAudits != undefined) {
        this.$refs.phptoAudits.initDatas(id, collectType);
      }
    },
    /**
     * 查询处理进度
     * @param {*} datas
     */
    clickTaskSchedule(datas) {
      if (this.$refs.progressTask != undefined) {
        this.$refs.progressTask.initDatas(datas);
      }
    },
    clickUploadPhoto(datas) {
      if (this.$refs.photoMod != undefined) {
        this.$refs.photoMod.initDatas(datas);
      }
    },
    /**
     * 审核失败照片打包
     */
    clickDownloadPhotos(datas) {
      this.showLoading = this.$notify.info({
        title: "温馨提示",
        message: "审核失败照片下载中...",
        duration: 0,
        showClose: false,
      });
      const params = {
        id: datas.id,
        collectType: datas.collectType,
      };
      downloadPhotos(params)
        .then((res) => {
          if (res.code == 0) {
            this.taskExportprocess(res.data.taskNo, datas.title);
          } else {
            this.showLoading.close();
            this.$notify({
              title: "警告",
              message: res.message,
              type: "warning",
              showClose: false,
            });
            return;
          }
        })
        .catch(() => {
          this.showLoading.close();
        });
    },
    /**
     * 查询任务进度
     */
    taskExportprocess(taskNo, title = "") {
      let that = this;
      exportprocess(taskNo)
        .then((res) => {
          if (res.data.state == 0) {
            setTimeout(() => {
              that.taskExportprocess(taskNo, title);
            }, 500);
          } else if (res.data.state == 1) {
            this.showLoading.close();
            that.$notify({
              title: "温馨提示",
              message: "不通过照片下载成功",
              type: "success",
              showClose: false,
            });
            //下载文件
            downFiles(res.data.url, title + ".rar");
          } else {
            this.showLoading.close();
            this.$notify({
              title: "警告",
              message: "不通过照片下载失败",
              type: "warning",
              showClose: false,
            });
          }
        })
        .catch(() => {
          this.showLoading.close();
        });
    },
    /**
     * 处理照片复核
     * @param {*} datas
     */
    funcPhotoResCheck(datas) {
      if (this.$refs.photoResCheck != undefined) {
        this.$refs.photoResCheck.initDatas(datas);
      }
    },
  },
  mounted() {
    this.funcQueryAllTask();
  },
};
</script>
