<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    :width="width"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    top="7vh"
  >
    <el-table
      border
      :data="progressList"
      ref="multipleTable"
      style="width: 100%"
      height="400"
      :header-cell-style="{ background: '#f5f7fa', color: '#606266' }"
    >
      <template #empty>
        <p>{{ progressLoading == true ? "数据加载中" : "暂无数据" }}</p>
      </template>
      <el-table-column
        align="left"
        prop="fileName"
        label="文件名称"
        width="340px"
      >
      </el-table-column>
      <el-table-column
        prop="photographer"
        label="摄影师"
        align="center"
      ></el-table-column>
      <el-table-column
        align="left"
        prop="createTime"
        label="处理时间"
        width="160px"
      >
        <template slot-scope="scope">
          {{ formDateTimeFormat(scope.row.createTime) }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="status"
        label="上传状态"
        width="80px"
      >
        <template slot-scope="scope">
          <div style="color: #1ccb66" v-if="scope.row.status == 1">
            导入成功
          </div>
          <div style="color: #ec0b0b" v-if="scope.row.status == 2">
            导入失败
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="left"
        prop="remark"
        label="上传结果"
        min-width="160px"
      ></el-table-column>
      <el-table-column align="center" prop="edits" label="操作" width="110px">
        <template slot-scope="scope">
          <el-button
            type="text"
            @click="clickCheckPhoto(scope.row)"
            v-if="CheckPhotoButton"
            >上传结果确认</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        align="left"
        prop="auditDtos"
        label="处理结果"
        min-width="160px"
      >
        <template slot-scope="scope">
          <span v-for="(_sub, idex) in scope.row.auditDtos" :key="idex">
            {{ _sub.collectName }}上传处理{{ _sub.photoCount }}张照片
          </span>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
import { getTaskSchedule } from "@/api/appreciation";
import { dateFormat } from "@/utils/date";
import { queryPageButton } from "@/api/permission";

export default {
  name: "progressView",
  props: {
    title: {
      type: String,
      default: "处理进度",
    },
    width: {
      type: String,
      default: "80%",
    },
  },
  computed: {},
  data() {
    return {
      visible: false,
      search: {},
      progressLoading: false,
      progressList: [],

      //按钮权限
      CheckPhotoButton: false,
    };
  },
  created() {
    this.queryButton();
  },
  methods: {
    initDatas(datas) {
      this.visible = true;
      this.progressList=[]
      const loads = this.$loading({
        lock: true,
        text: "数据加载中",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.1)",
      });
      //查看处理任务进度
      getTaskSchedule({ collectType: datas.collectType, id: datas.id })
        .then((resp) => {
          loads.close();
          if (resp.code == 0) {
            this.progressList = resp.data.content;
          }
        })
        .catch(() => {
          loads.close();
        });
    },
    queryButton() {
      const data = 222;
      queryPageButton(data).then((resp) => {
        for (const button of resp.data) {
          if (button.code === "CheckPhotoButton") {
            this.CheckPhotoButton = true;
          }
       
        }
      });
    },
    formDateTimeFormat(dateValue, format = "YYYY-mm-dd HH:MM:SS") {
      if (dateValue != null) {
        return dateFormat(format, new Date(dateValue));
      }
    },
    clickCheckPhoto(datas) {
      this.$emit("funcPhotoResCheck", datas);
    },
  },
  mounted() {},
};
</script>
